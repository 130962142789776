/* ========================================================================
 * SCRIPTS BLULAB
 * ======================================================================== */
(function ($) {

  // Isotope
  function isotope_settings() {
    $('.isotope-list').isotope({
        itemSelector: '.isotope-item',
        layoutMode: 'masonry',
    });
  }

  $(document).ready(function () {

    // MATCH HEIGHT
    $('.equal-el').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    // Isotope
    isotope_settings();

    // SLIDER
    $('.custom-slider .slides').on('init', function(slick) {
    $('.custom-slider .slides').removeClass('cS-hidden');
    }).slick({
      lazyLoad: 'ondemand',
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      dots: false,
      infinite: true,
      speed: 800,
      fade: true,
      cssEase: 'linear',
      arrows: false,
      prevArrow: '<span class="slide-button slide-button-prev"><i class="fa fa-angle-left"></i></span>',
      nextArrow: '<span class="slide-button slide-button-next"><i class="fa fa-angle-right"></i></i></span>',
      mobileFirst: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false
    });

    // SLIDER
    $('.buche-slider .slides').on('init', function(slick) {
      $('.buche-slider .slides').removeClass('cS-hidden');
      }).slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        dots: false,
        infinite: true,
        speed: 800,
        fade: true,
        cssEase: 'linear',
        arrows: true,
        prevArrow: '<span class="slide-button slide-button-prev"><i class="fa fa-angle-left"></i></span>',
        nextArrow: '<span class="slide-button slide-button-next"><i class="fa fa-angle-right"></i></i></span>',
        mobileFirst: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnFocus: false,
        pauseOnHover: false
      });

  });

  $(window).load(function () {
    // Isotope
    isotope_settings();
  });

  $(window).resize(function () {
    // Isotope
    isotope_settings();
  });

  $(window).scroll(function () {
  });

}(jQuery)); // Fully reference jQuery after this point.
